// Imports
import '../js/menu.js'
import '../js/tiles.js'
import '../js/mousepointer.js'

// Front Page
const nplOfferBtn = document.querySelectorAll('#offer-button')
nplOfferBtn.forEach( btn => {
  btn.addEventListener('click', () => {
    btn.parentNode.classList.add('cover')
  })
})

// Trigger User Centrics Button
if(document.querySelector('.usercentrics-trigger')) {
  document.querySelector('.usercentrics-trigger').addEventListener('click', e => {
    e.preventDefault()
    UC_UI.showSecondLayer()
  })
}