/// Mouse Pointer
const loesungen = document.getElementById('loesungen-filter')
const loesungPtr = document.getElementById('loesungen-pointer')

let animID
const mqCheck = window.matchMedia("(pointer:fine)")

if(mqCheck.matches && loesungen) {
  
  loesungen.addEventListener('mouseenter', e => {
    loesungPtr.classList.remove('hide')
    animID = requestAnimationFrame(npl_mousepointer)
    
  })

  loesungen.addEventListener('mouseleave', e => {
    cancelAnimationFrame(animID)
    loesungPtr.classList.add('hide')
  })
}

// Track Mouse position, set the css variable with the coordinate px
// Use requestAnimationFrame() to adapt to monitors framerate 
function npl_mousepointer() {
  loesungen.addEventListener('mousemove', e => {
    loesungPtr.setAttribute('style', `top:${e.clientY}px;left:${e.clientX}px;`)
  })
  animID = requestAnimationFrame(npl_mousepointer)
}
