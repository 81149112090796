// Add Menu Functionality
const headerEl = document.querySelector('#header.header')
const menuElWithChildren = document.querySelectorAll('.menu-item-has-children')
const menuClose = document.querySelector('#menu-close')
const menuMobile = document.getElementById('mobile-menu-trigger')

if(headerEl) {
  menuElWithChildren.forEach( item => {
    item.querySelector('.sub-menu').setAttribute( 'style', `--max-menu-height:${item.scrollHeight}px;max-height:0px;`)
    openMenu(item, headerEl)
  })
  
  headerEl.addEventListener('click', (e) => {
    //e.preventDefault()
    if(e.target.classList.contains('header')) {
      closeMenu(headerEl, menuElWithChildren)
    }
  })
  
  menuClose.addEventListener('click', (e) => {
    e.preventDefault()
    closeMenu(headerEl, menuElWithChildren)
  })

  menuMobile.addEventListener('click', (e) => {
    e.preventDefault();
    headerEl.classList.toggle('active')
  })
}

function closeMenu(headerEl, menuItems) {
  headerEl.classList.remove('active')
  menuItems.forEach( item => {
    item.classList.remove('active')
  })
}

function openMenu( menuEl = '', containerEL = '' ) {
  menuEl.addEventListener('click', (e) => {
    menuEl.classList.toggle('active')
    containerEL.classList.add('active')
  })
}