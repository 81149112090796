// Aufbau der Kachelansichten

const tilesWrapper = document.querySelectorAll('.et_pb_row.tiles_slider .tiles_wrapper')
const loesungenContainer = document.querySelector('#loesungen-wrapper')
const loesungenFilter = document.querySelectorAll('.loesung_filter')
const standorteContainer = document.querySelector('#standorte-container')
const loesungenSubpageContainer = document.querySelector('#loesungen-subpage-wrapper')
const newsContainer = document.querySelector('#news-tiles')
const newsFilterBtns = document.querySelectorAll('#news .tile_filter')
const servicesContainer = document.getElementById('services-wrapper')
const breakpointMobile = 768
const breakpointTablet = 980

// Set up fetch parameters
const optsHeaders = new Headers({
  "Cache-Control": "max-age=3600"
})

let options = {
  method: "GET",
  headers: optsHeaders
}

// Set the scroll element on sliding-tiles
tilesWrapper.forEach( tw => {
  npl_slide_controller(tw)
  grabScrollX(tw)
}) 

// Build tiles
// Checking for container element before executing
// Lösungen Hero Section
if(loesungenContainer) {
  const loesungen = npl_getJSON('loesungen', options)
  const schliessenBtn = document.getElementById('loesungen-schliessen')

  loesungenFilter.forEach( (btn, key, arr) => {
    btn.addEventListener('click', (e) => {
      e.preventDefault()

      schliessenBtn.parentNode.classList.remove('hide')

      let filter = [e.target.dataset.cat]

      loesungen.then(data => {
        npl_buildTiles(data, loesungenContainer, 100, '', filter)

        loesungenContainer.setAttribute('style', 'max-height:' + (loesungenContainer.scrollHeight + 50) + 'px')

        loesungenContainer.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start"
        })

      })
      .catch(err => console.error(err))
    })
  })

  schliessenBtn.addEventListener('click', (e) => {
    e.preventDefault()

    loesungenContainer.setAttribute('style', 'max-height:0px')

    document.getElementById('loesungen').scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start"
    })

    setTimeout(() => {
      loesungenContainer.innerHTML = ''
    }, 300)


    e.target.parentNode.classList.add('hide')
  })

}

// Lösungen Subpage Overview Module
if(loesungenSubpageContainer) {
  let filter = [loesungenSubpageContainer.dataset.cat]
  const loesungen = npl_getJSON('loesungen', options)

  loesungen.then( data => {
    npl_buildTiles(data, loesungenSubpageContainer, 100, '', filter)
  })
  .catch(err => console.error(err))

}

// Standorte Slider
if(standorteContainer) {
  const standorte = npl_getJSON('standorte')

  standorte.then(data => {
    npl_buildTiles(data, standorteContainer, 10)
  })
  .catch(err => console.error(err))
}

// News Slider
if(newsContainer) {
  const news = npl_getJSON('news', options)
  let tags = newsContainer.dataset.tags
  const count = newsContainer.dataset.count

  news.then(data => {
    
    if(tags.length > 0) {
      npl_buildTiles(data, newsContainer, count, tags)
    } else {
      npl_buildTiles(data, newsContainer, count)
    }

    // Add filtering
    newsFilterBtns.forEach((btn, key, arr) => {

      btn.addEventListener('click', (e) => {
        e.preventDefault()

        arr.forEach( el => {
          if(el.id === 'reset') {
            el.classList.add('active')
          } else {
            el.classList.remove('active')
          }
        })

        if(e.target.id === 'reset') {
          npl_buildTiles(data, newsContainer, 6)
          e.target.classList.remove('active')
        } else {
          let categories = [e.target.dataset.cat]
          npl_buildTiles(data, newsContainer, 6, '', categories)
          e.target.classList.add('active')
        }
      })
    })
  })
  .catch(err => console.error(err))
}


// Functions
function npl_getJSON(apiEndpoint, options = {}) {
  return new Promise((resolve, reject) => {
    fetch(window.location.origin + '/wp-json/nextpharma-logistics/' + apiEndpoint, options).then(res => {
      return res.json();
    }).then(data => {
      resolve(data)
    }).catch(err => {
      reject(err)
    })
  })
}

function npl_buildTiles(data = [], container, count = 6, tags = '', categories = '') {
  container.innerHTML = ''
  let internalCounter = 0

  if(document.querySelector('#news-tiles-scrollbar')) {
    document.querySelector('#news-tiles-scrollbar').parentNode.removeChild(document.querySelector('#news-tiles-scrollbar'))
  }

  let lang = container.dataset.lang ? container.dataset.lang : 'de'

  let contact = container.dataset.contact ? true : false
  
  let filteredData = data.filter( el => {
    
    if(tags.length > 0 && !el.tags.some( a => tags.includes(a)) ) {
      return false
    } else if (categories.length > 0 && !el.categories.some( a => categories.includes(a))) {
      return false
    } else {
      return true
    }
  })

  if(filteredData.length > 0) {

    filteredData.forEach( (el, key) => {
     
      if(lang === el.wpml.language_code && internalCounter < count) {
        
        // Build Data
        let id = (el.id) ? el.id : 'no-id'
        let title = (el.title) ? el.title : ''
        let date = (el.date) ? el.date : ''
        let image = (el.image) ? el.image : '/wp-content/themes/nextpharma-logistics/img/tile-default.jpg'
        let excerpt = el.excerpt ? `<p>${el.excerpt}</p>` : ''
        let link = (el.link) ? el.link : '#'
        let categories = (el.categories.length > 0) ? el.categories.join(' ') : false
        let adresse = (el.adresse.length > 1) ? el.adresse[1].name + ', ' + el.adresse[0].name  : false
        let parent = (el.parent > 0) ? '' : 'parent'
        let tags = (el.tags.length > 0) ? el.tags.join(' ') : false
        let filtered = ''
        
        if(document.body.classList.contains('postid-' + id)) {
          filtered = 'hide'
        }
  
        if(contact) {
          link = link + '#kontakt'
          excerpt = ''
        }
        
        let node = document.createElement('div')
        node.id = id
        node.classList.add('tile_item')
        if(parent) node.classList.add(parent)
        if(filtered) node.classList.add(filtered)
        if(categories) node.dataset.cat = categories
        if(tags) node.dataset.tags = tags
        if(adresse) node.dataset.adress = adresse
      
        let output = `
            <a href="${link}" alt="${title}">
              <div class="tile_image"><img src="${image}" alt="${title}"></div>
            </a>`
        output += (date) ? `<p class="tile_meta news">${date}</p>` : ''
        output += (adresse) ? `<p class="tile_meta">${adresse}</p>` : ''
        output += `
            <a href="${link}" alt="${title}">
              <h3>${title}</h3>
            </a>
            ${excerpt}
            <a class="tile_link" alt="read more about ${title}" href="${link}"><span class="tile_plus_icon"></span></a>`
          
        node.innerHTML = output
        container.append(node)

        internalCounter++
      }
    })
  } else {
    let node = document.createElement('div')
    node.classList.add('tile_item')
  
    let output = `
        <a href="#" alt="no tiems found">
          <div class="tile_image"><img src="/wp-content/themes/nextpharma-logistics/img/tile-default.jpg" alt="Nextpharma Logistics"></div>
        </a>`
    output += `
        <a href="#" alt="no items found">
          <h3>No items found</h3>
        </a>`
      
    node.innerHTML = output
    container.append(node)
  }

  npl_slide_controller(container)
  grabScrollX(container)
}

// Grab and scroll funktion
// Quelle: https://htmldom.dev/drag-to-scroll/
function grabScrollX(ele) {
	
	//ele.style.cursor = 'grab'; // Standart Cursor Style definieren
	
	let pos = { left: 0, x: 0 };
	
	const mouseDownHandler = function (e) {
			e.preventDefault();
					// ele.style.cursor = 'grabbing'; // Cursor Style wenn Maus gedrückt
					ele.style.userSelect = 'none';
	
					pos = {
									left: ele.scrollLeft,
									x: e.clientX
					};
	
					document.addEventListener('mousemove', mouseMoveHandler);
					document.addEventListener('mouseup', mouseUpHandler);
	};
	
	const mouseMoveHandler = function (e) {
					// How far the mouse has been moved
					const dx = e.clientX - pos.x;
	
					// Scroll the element
					ele.scrollLeft = pos.left - dx;
	};
	
	const mouseUpHandler = function () {
					//ele.style.cursor = 'grab'; // Cursor Style reset
					ele.style.removeProperty('user-select');
	
					document.removeEventListener('mousemove', mouseMoveHandler);
					document.removeEventListener('mouseup', mouseUpHandler);
	};
	
	// Attach the handler
	ele.addEventListener('mousedown', mouseDownHandler);

}

function npl_slide_controller(container) {

  let desktop = (document.body.clientWidth > breakpointTablet) ? true : false
  let scrollMax = container.scrollWidth - container.clientWidth
  let scrollSections = Math.floor(container.scrollWidth / container.clientWidth + 1)

  if(scrollMax > 0) {

    const scrollbarNode = npl_create_scrollBar(container)
    container.parentNode.parentNode.append(scrollbarNode)

    let scrollWidth = Math.floor(scrollbarNode.scrollWidth / scrollSections)

    if(desktop) {
      scrollbarNode.setAttribute('style', `--scrollbar-width:${scrollWidth}px;--scrollbar-position:0px;`)
  
      container.addEventListener('scroll', (e) => {
        let scrollProgress = container.scrollLeft/scrollMax
        let scrollPosition = (scrollProgress * scrollbarNode.clientWidth) - (scrollWidth * scrollProgress)
        scrollbarNode.setAttribute('style', `--scrollbar-width:${scrollWidth}px;--scrollbar-position:${scrollPosition}px;`)
      })
    }
    
    scrollbarNode.childNodes.forEach( el => {
      el.addEventListener('click', (e) => {
        if(e.target.classList.contains('prev')) {
          container.scrollLeft = (container.scrollLeft > 0) ? container.scrollLeft - container.clientWidth : 0
        }
        if(e.target.classList.contains('next')) {
          container.scrollLeft = (container.scrollLeft < scrollMax) ? container.scrollLeft + container.clientWidth : scrollMax
        }
      })
    })
  }

}

function npl_create_scrollBar(container) {
  let scrollbar = document.createElement('div')
  scrollbar.classList.add('tiles_slider_scrollbar')
  scrollbar.id = container.id + '-scrollbar'
  scrollbar.innerHTML = '<span class="prev"></span><span class="next"></span>'

  return scrollbar
}